import React from "react";
import "../styles/FadeInSection.css";

class FadeInSection extends React.Component {
  state = { isVisible: false };
  ref = React.createRef();
  observer = null

  componentDidMount() {
    const options = {
    }

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) this.setState({isVisible : true});
      });
    }, options);

    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.ref.current);
  }

  render() {
    return (
      <div
        className={`fade-in-section ${this.state.isVisible ? "is-visible" : ""}`}
        ref={this.ref}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FadeInSection;
