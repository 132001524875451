import React from "react";
import TypedSubheader from "./TypedSubheader";
import Landing from "./Landing";
import Body from "./Body";
import Header from "./Header";

class App extends React.Component {
  // render() {
  //   return (
  //     <div>
  //       <Header/>
  //       <Landing />
  //       <Body />
  //     </div>
  //   );
  // }
  render() {
    return (
      <div className="center-screen">
          <TypedSubheader start="100">Hey there!</TypedSubheader>
          <TypedSubheader start="1000">
            This is my space to create my own APIs!
          </TypedSubheader>
      </div>
    );
  }
}

export default App;
