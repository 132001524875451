import React from "react";
import "../styles/TypedSubheader.css";

class TypedSubheader extends React.Component {
  state = { displayMsg: "", inheritedMsg: this.props.children };

  myInterval = null;

  componentDidMount() {
    setTimeout(this.beginTyping, this.props.start
        );
  }

  beginTyping = () => {
    this.myInterval = setInterval(() => {
        const { displayMsg, inheritedMsg } = this.state;
        if (inheritedMsg.length <= 0) clearInterval(this.myInterval);
        const newDisplay = displayMsg.concat(inheritedMsg.charAt(0));
        const newStr = inheritedMsg.substring(1, inheritedMsg.length);
        this.setState({ displayMsg : newDisplay, inheritedMsg : newStr });
    }, 30);
  };

  render() {
    return <div className="typed-subtext">{this.state.displayMsg}</div>;
  }
}

export default TypedSubheader;
