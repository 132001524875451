import React from "react";
import "../styles/CopyClipboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

class CopyClipboard extends React.Component {
  state = { copyClicked: false };

  handleClick = (e) => {
    var ref = document.getElementById("to-copy");
    ref.select();
    document.execCommand("copy");
    this.handlePopupLogic();
  };

  handlePopupLogic = async () => {
    await this.setState({ copyClicked: false });
    this.setState({ copyClicked: true });
  };

  copySuccessText = () => {
    return (
      <div className="copy-popup">
        <p>
          Copied <FontAwesomeIcon className="check-icon" icon={faCheck} />
        </p>
      </div>
    );
  };

  render() {
    return (
      <div className="clipboard-container">
        <input id="to-copy" type="text" value={this.props.default} readOnly />
        <div className="animation-container">
          {this.state.copyClicked && this.copySuccessText()}
          <div className="copy-btn" onClick={this.handleClick}>
            Copy
            <FontAwesomeIcon className="copy-icon" icon={faCopy} />
          </div>
        </div>
      </div>
    );
  }
}

export default CopyClipboard;
