import React from "react";
import "../styles/Landing.css";
import TypedSubheader from "./TypedSubheader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

class Landing extends React.Component {

  handleClick = () => {
    var ref = document.getElementById("body-section");
    if (ref) ref.scrollIntoView({behavior : 'smooth'});
  };

  landingContainer() {
    return (
      <div id="first-container" className="landing-container">
        <div className="landing-content greetings">
          <p id="hi">
            Hi, <span id="im"> I'm </span>
          </p>
          <p id="name">Huynh Tran</p>
          <TypedSubheader start="3000">Software Engineer</TypedSubheader>
          <TypedSubheader start="4300">(with a specialization in devops)</TypedSubheader>
            <FontAwesomeIcon
              onClick={this.handleClick}
              className="arrow-icon"
              icon={faAngleDoubleDown}
            />
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.landingContainer()}</div>;
  }
}

export default Landing;
