import React from "react";
import "../styles/Header.css";

class Header extends React.Component {
  handleHomeClick = async () => {
    window.scrollTo(0, 0);
  };

  handleProjectsClick = async () => {
    var ref = document.getElementById("body-section");
    if (ref) ref.scrollIntoView({ behavior: "smooth" });
  };

  handleAboutClick = async () => {
    var ref = document.getElementById("about-section");
    if (ref) ref.scrollIntoView({ behavior: "smooth" });
  };

  handleContactClick = async () => {
    var ref = document.getElementById("contact-section");
    if (ref) ref.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <div className="header-container">
        <div className="item-wrap">
          <div className="header-item noselect" onClick={this.handleHomeClick}>
            <p>Home</p>
          </div>
        </div>
        <div className="item-wrap">
          <div
            className="header-item noselect"
            onClick={this.handleProjectsClick}
          >
            <p>Experience</p>
          </div>
        </div>
        <div className="item-wrap">
          <div className="header-item noselect" onClick={this.handleAboutClick}>
            <p>About</p>
          </div>
        </div>
        <div className="item-wrap">
          <div
            className="header-item noselect"
            onClick={this.handleContactClick}
          >
            <p>Contact</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
