import React from "react";
import "../styles/Body.css";
import ExperienceCard from "./ExperienceCard";
import CopyClipboard from "./CopyClipboard";
import FadeInSection from "./FadeInSection";
import ConditionalWrapper from "./ConditionalWrapper";
import ProjectWebsite from "../images/project-website-thumbnail.png";
import ChrisProject from "../images/chris-project.png";
import Egress from "../images/egress-HQ.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSmile } from "@fortawesome/free-solid-svg-icons";

class Body extends React.Component {
  isIE = false || !!document.documentMode;

  IEwrapper = (children) => {
    return <FadeInSection>{children}</FadeInSection>;
  };

  projects() {
    return (
      <div id="projects-section" className="section-container">
        <h1 className="section-header">Experience</h1>
        <div className="section-content">
          <ul>
            <li>
              <ConditionalWrapper
                condition={!this.isIE}
                wrapper={this.IEwrapper}
              >
                {/* delete this block later when you actually put something*/}
                <div className="smile-block">
                  <p>Stay tuned!</p>
                  <FontAwesomeIcon className="smile-icon" icon={faSmile} />
                </div>
              </ConditionalWrapper>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  aboutMe() {
    return (
      <div id="about-section" className="section-container">
        <ConditionalWrapper condition={!this.isIE} wrapper={this.IEwrapper}>
          <h1 className="section-header">About</h1>
          <div className="section-content">
            <p>
              For as long as I can remember, I've always been drawn to computers
              and everything related to it. At first, I started to learn how to
              write code to see what it was like. Ever since I wrote my first
              simple "Hello, world!" program on terminal, I've been hooked and
              haven't looked back!
            </p>
            <p>
              I am passionate about constantly learning whether it's related to
              tech or not. I believe that if you're not learning, you're not
              living!
            </p>
            <p>
              I spent a few years in the US Army where I had the opportunity to
              meet some amazing people and lifelong friends. During my service,
              I was awarded numerous medals for meritorious service. While the
              medals I've earned may not really mean much to anyone else, they
              are my proof and reminder of my dedication to this country.
            </p>
          </div>
        </ConditionalWrapper>
      </div>
    );
  }

  contact() {
    return (
      <div id="contact-section" className="section-container">
        <div className="section-content contact-content">
          <h1 id="contact-header" className="section-header">
            Let's get in touch!
          </h1>
          <p>
            You can connect with me on{" "}
            <a href="https://www.linkedin.com/in/huynh-tran-b7a920196/">
              LinkedIn
            </a>{" "}
            or by my email down below!
          </p>
          <div className="contact-nav">
            <a href="https://www.linkedin.com/in/huynh-tran-b7a920196/">
              <FontAwesomeIcon className="linkedin-icon" icon={faLinkedin} />
            </a>
            <CopyClipboard default="huynh@huynhtran.tech" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="body-container">
        <div id="body-section" className="body-content">
          {this.projects()}
          {this.aboutMe()}
          {this.contact()}
        </div>
      </div>
    );
  }
}

export default Body;
