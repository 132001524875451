import React from "react";
import "../styles/ExperienceCard.css";

class ExperienceCard extends React.Component {
  render() {
    return (
      <div className="experience-container">
        <div className="content-cover">
          <a href={this.props.href} className="noselect">
            <h2>{this.props.header}</h2>
            <p className="experience-subtitle">{this.props.subtitle}</p>
            <div className="zoom-in">
              <img src={this.props.img} alt="" />
            </div>
          </a>
        </div>
        <div className="experience-content">{this.props.children}</div>
      </div>
    );
  }
}

export default ExperienceCard;
